<template>
  <div class="modal-content friend-modal">
    <div class="icon-box">
      <img class="icon-img" src="@/assets/estamp.png" />
    </div>
    <p class="font-18 font-b main-color">You have {{ stamp }} stamps</p>
    <p class="font-18 font-b main-color">Confirm transfer</p>
    <input class="Bold" type="number" oninput="value=value.replace(/[^\d]/g,'')" :max="stamp" v-model="transStampsQty" />
    <p class="font-16 to-text" v-if="userDetail.userId">
      <span class="font-18 font-b main-color" v-if="userDetail.userName">to {{ userDetail.userName }}</span>
      <span class="font-18 font-b main-color" v-else>to {{ userDetail.firstName }} {{ userDetail.lastName }}</span>
    </p>
    <p class="font-16 gery-color" style="margin-top: 30px">Only stamps earned from purchase are transferrable!</p>
    <div class="btn-box" style="display: flex; margin-top: 30px">
      <button class="btn Medium" @click="cancelEvent">Cancel</button>
      <button
        class="btn Bold"
        @click="checkEvent"
        :class="{ 'disabled': !transStampsQty || transStampsQty < 0 || transStampsQty.toString().indexOf('.') >= 0 }"
        :disabled="!transStampsQty || transStampsQty < 0 || transStampsQty.toString().indexOf('.') >= 0"
      >
        Confirm
      </button>
    </div>
  </div>
</template>
<script>
import config from "../config";
export default {
  name: "transferModel",
  mixins: [config.globalMixin],
  data() {
    return {
      transStampsQty: "",
      friendList: [],
    };
  },
  props: ["stamp", "stampCardId", "campaignId", "userDetail"],
  methods: {
    checkEvent() {
      this.$emit("confirm", this.transStampsQty);
    },
    cancelEvent() {
      this.$emit("cancel");
    },
  },
};
</script>
