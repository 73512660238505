<template>
  <ion-page id="select-friend">
    <ion-modal
      @didDismiss="showTransfer = false"
      :is-open="showTransfer"
      css-class="tcc-modal"
      :animated="false"
    >
      <div class="model-box">
        <div class="ion-page">
          <transferModel
            :stamp="stamp"
            :stampCardId="stampCardId"
            :campaignId="campaignId"
            :userDetail="userDetail"
            @confirm="confirmEvent"
            @cancel="cancel"
          ></transferModel>
        </div>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar
        :class="{ 'letter-ios-14': $store.state.deviceType == 1 }"
        :bigtitle="true"
        :noboder="true"
        title="Select friend"
        :showBack="true"
      ></topbar>
      <div class="search-friend-box margin-top-160">
        <div class="input-box">
          <ion-input
            type="text"
            enterkeyhint="search"
            v-model="searchValue"
            v-on:keyup.enter="searchEvent()"
            placeholder="Search by email/mobile/card number "
          />
          <img class="search-icon" src="@/assets/search.png" />
        </div>
        <!-- <button class="scan-btn">
          <img
            class="scan-img"
            src="@/assets/scan_friend.png"
            v-if="!searchValue"
            @click="goScanEvent"
          />
          <span class="font-16 sub-color" v-else @click="cancelEvent">Cancel</span>
        </button> -->
      </div>
      <div class="share-friend-box">
        <img class="friends-icon" src="@/assets/friends.png" />
        <div class="text font-17">
          <!-- <p>Your friend is not on the list?</p> -->
          <!-- <a class="sub-color" @click="showTransferEvent(2)">Share by link</a> -->
          <p class="title">How to share coins / estamps with friends:</p>
          <p>1. Ensure your friend has the Ritchies Card App or is a member.</p>
          <p>
            2. Enter your friend's email address (if this doesn't work, try their mobile).
          </p>
          <p>3. Click Search on keyboard</p>
          <p>
            4. If your friend is a member, their name will show. Tap their name to
            transfer coins / estamps.
          </p>
        </div>
      </div>
      <div class="no-data-box" v-if="noResult">
        <img src="@/assets/noresult.png" />
        <p class="font-17 label-color">No results</p>
      </div>
      <div class="friend-list-box" v-else-if="friendList.length && searchValue">
        <p class="font-14 label-color m-l-20">Your friends list</p>
        <ul>
          <li v-for="(item, index) in friendList" :key="index">
            <img class="user-photo" v-lazy="item.icon ? item.icon : defaultIcon" />
            <p class="font-18 font-b main-color name-box" v-if="item.userName">
              {{ item.userName }}
            </p>
            <p class="font-18 font-b main-color name-box" v-else>
              {{ item.firstName }} {{ item.lastName }}
            </p>
            <button class="btn font-m font-14" @click="selectUserEvent(item)">
              Transfer
            </button>
          </li>
        </ul>
      </div>
      <div class="friend-list-box" v-else-if="recordList.length && !searchValue">
        <p class="font-14 label-color m-l-20">Your friends list</p>
        <ul>
          <li v-for="(item, index) in recordList" :key="index">
            <img class="user-photo" v-lazy="item.icon ? item.icon : defaultIcon" />
            <p class="font-18 font-b main-color name-box" v-if="item.userName">
              {{ item.userName }}
            </p>
            <p class="font-18 font-b main-color name-box" v-else>
              {{ item.firstName }} {{ item.lastName }}
            </p>
            <button class="btn font-m font-14" @click="selectUserEvent(item)">
              Transfer
            </button>
          </li>
        </ul>
      </div>
      <div class="content"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonModal, IonInput } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import transferModel from "@/components/transferModel.vue";
import config from "../config";
export default {
  name: "selectFriend",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonModal, transferModel, IonInput },
  data() {
    return {
      searchValue: "",
      stamp: "",
      stampCardId: "",
      showTransfer: false,
      userDetail: {},
      friendList: [],
      recordList: [],
      noResult: false,
      type: 1,
      shareData: {},
    };
  },
  methods: {
    async searchEvent() {
      this.noResult = false;
      const objdata = this.getAuthObj();
      objdata.content = this.searchValue;
      objdata.apiUrl = this.apiConfig.searchUser;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.sendGAEvent("Click", "Select Friend", "Search");
      this.friendList = data.data.result;
      if (this.friendList.length == 0) {
        this.noResult = true;
      }
    },
    async getRecentTransferRecord() {
      const objdata = this.getAuthObj();
      objdata.recordSize = 5;
      objdata.apiUrl = this.apiConfig.getRecentTransferRecord;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.recordList = data.data.result;
    },
    async transferStampEvent(transStampsQty) {
      console.log(this.userDetail);
      const objdata = this.getAuthObj();
      objdata.transStampsQty = transStampsQty;
      objdata.transUserId = this.userDetail.userId;
      objdata.stampCardId = this.stampCardId;
      objdata.apiUrl = this.apiConfig.transferStamp;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.stamp -= transStampsQty;
      this.successToast("Success!");
    },
    async transferStampByCode(transStampsQty) {
      const objdata = this.getAuthObj();
      objdata.transStampsQty = transStampsQty;
      objdata.stampCardId = this.stampCardId;
      objdata.apiUrl = this.apiConfig.transferStampByCode;
      const data = await this.$store.dispatch("apiEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      let str = "";
      if (this.$route.query.mode == "stamp") {
        str = "eStamp";
      } else {
        str = "coins";
      }

      this.shareData = {
        title: "Check it out! New stamp(s) is available!",
        description: `${this.$store.state.form.userInfo.firstName} transfer ${transStampsQty} ${str} to you, open your ${this.$store.state.appName} and check it out!`,
        image: "",
        code: data.data.result,
      };
      this.shareEvent(this.shareData);
    },

    selectUserEvent(obj) {
      this.sendGAEvent("Click", "Select Friend", "Transfer");
      this.userDetail = obj;
      this.showTransferEvent(1);
    },
    showTransferEvent(type) {
      this.type = type;
      if (type == 2) {
        this.sendGAEvent("Click", "Select Friend", "Share by link");
      }
      this.showTransfer = true;
    },
    confirmEvent(transStampsQty) {
      this.showTransfer = false;
      this.sendGAEvent("Click", "Select Friend", "Confirm");
      setTimeout(() => {
        if (this.type == 1) {
          this.transferStampEvent(transStampsQty);
        } else if (this.type == 2) {
          this.transferStampByCode(transStampsQty);
        }
      }, 100);
    },
    cancel() {
      this.showTransfer = false;
      this.userDetail = {};
    },
    cancelEvent() {
      this.searchValue = "";
      this.friendList = [];
    },
    goScanEvent() {
      this.$router.push({
        path: "/scanCode",
        query: {
          type: 1,
        },
      });
    },
  },
  ionViewDidEnter() {
    this.stamp = this.$route.query.stamp;
    this.stampCardId = this.$route.query.stampCardId;
    this.campaignId = this.$route.query.campaignId;
    if (this.$store.state.searchValue) {
      this.searchValue = this.$store.state.searchValue;
      this.searchEvent();
    }
    this.$store.state.exitApp = false;
    this.getRecentTransferRecord();
    this.sendGAPageView("Select friend");
  },
};
</script>
